/**
 * Displays HTML contents in a sandboxed iframe.
 */
export class EvpHtmlEmbed extends HTMLElement {
  private iframe?: HTMLIFrameElement
  private currentHtml?: string
  private contentHeight = 0
  private refreshEnqueued = false
  private resizeHandlerInstalled = false
  private removeIntersectionObserver?: () => void

  constructor() {
    super()
  }

  // https://html.spec.whatwg.org/multipage/custom-elements.html#concept-custom-element-definition-observed-attributes
  static get observedAttributes() {
    return ["html"]
  }

  // https://html.spec.whatwg.org/multipage/custom-elements.html#concept-custom-element-definition-lifecycle-callbacks
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks
  connectedCallback() {
    this.refresh()

    // Add an intersection observer to call `enqueueRefresh` when the element becomes visible.
    // This is needed because the iframe's height is set to 1px initially, and the iframe's
    // `contentWindow` is not available until the iframe is visible.
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.enqueueRefresh()
      }
    })
    observer.observe(this)
    this.removeIntersectionObserver = () => {
      observer.disconnect()
    }
  }

  // https://html.spec.whatwg.org/multipage/custom-elements.html#concept-custom-element-definition-lifecycle-callbacks
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks
  attributeChangedCallback() {
    this.refresh()
  }

  // https://html.spec.whatwg.org/multipage/custom-elements.html#concept-custom-element-definition-lifecycle-callbacks
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks
  disconnectedCallback() {
    this.refresh()
    this.removeIntersectionObserver?.()
  }

  refresh(): void {
    if (!this.iframe) {
      this.iframe = this.createIframe()
      this.iframe.onload = () => {
        this.refresh()
      }
      return this.refresh()
    }

    const iframe = this.iframe
    const contentWindow = iframe.contentWindow
    if (!contentWindow) {
      // The DOM element may not be attached to the document yet,
      // and so the iframe may not yet have a window.
      // We are waiting for it to load (after which the `refresh`
      // method will be called again). So do nothing for now.
      return
    }

    if (!this.resizeHandlerInstalled) {
      this.resizeHandlerInstalled = true
      window.addEventListener("resize", () => {
        this.enqueueRefresh()
      })
    }

    const html = this.getAttribute("html")
    const doc = contentWindow.document
    if (html != null && html !== this.currentHtml) {
      this.currentHtml = html
      const htmlContent = this.getHtmlContent(html)
      doc.open()
      doc.write(htmlContent)
      doc.close()
    }

    const height = doc.body.offsetHeight
    if (height !== this.contentHeight) {
      this.contentHeight = height
      iframe.height = `${this.contentHeight}px`
    }
  }

  private createIframe() {
    const iframe = document.createElement("iframe")
    iframe.width = "100%"
    iframe.height = "1px"
    iframe.src = "about:blank"
    iframe.setAttribute("scrolling", "no")
    iframe.setAttribute(
      "sandbox",
      "allow-same-origin allow-popups allow-top-navigation allow-popups-to-escape-sandbox",
    )
    iframe.style.border = "0"
    iframe.style.display = "block"
    this.appendChild(iframe)
    return iframe
  }

  private getHtmlContent(html: string): string {
    const theme =
      document.documentElement.getAttribute("data-evp-theme") || "light"
    const htmlContent = [
      "<!DOCTYPE html>",
      `<html data-evp-theme="${theme}">`,
      "<head>",
      "<title></title>",
      `<link rel='stylesheet' href="${$("#application-css").attr("href")}">`,
      "</head>",
      '<body class="iframe-event-main-content" style="background: transparent;">',
      // A wrapper div with `overflow: hidden` is needed to encapsulate the margin of its children
      // and prevent the so-called "margin collapse" effect.
      // See: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing
      '<div style="overflow:hidden">',
      html,
      "</div>",
      "</body></html>",
    ]
    return htmlContent.join("")
  }

  private enqueueRefresh(): void {
    if (!this.refreshEnqueued) {
      this.refreshEnqueued = true
      requestAnimationFrame(() => {
        this.refreshEnqueued = false
        this.refresh()
      })
    }
  }
}

if (window.customElements) {
  window.customElements.define("evp-html-embed", EvpHtmlEmbed)
}

declare global {
  interface HTMLElementTagNameMap {
    "evp-html-embed": EvpHtmlEmbed
  }
}
declare module "react" {
  namespace JSX {
    interface IntrinsicElements {
      "evp-html-embed": React.DetailedHTMLProps<
        React.HTMLAttributes<EvpHtmlEmbed> & { html: string },
        EvpHtmlEmbed
      >
    }
  }
}
